import {
	MediaKitCustomPlatformValueStat,
	MediaKitCustomStatFormSchema,
	MediaKitPlatformBlock,
	MediaKitStat,
	UpdateMediaKitBlock,
} from "@withjuly/fabric";
import { Plus, Warning } from "@withjuly/julycons/bold";
import { hasInsufficientData } from "../utils";
import { Button, Label, Modal, Tag, Tooltip } from "@withjuly/solisv2";
import { ToggleSwitchInput } from "~/components/Input/ToggleSwitchInput";
import { useState } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { ZodInput } from "~/components/Input/ZodInput";
import { useZodForm } from "~/utils/hooks/zod-form";
import { trpc } from "~/components/Utility/trpc";

type AddStat =
	| { open: false }
	| { open: true; stat: MediaKitCustomPlatformValueStat; isNew: false }
	| { open: true; stat: undefined; isNew: true };

export const PlatformStatsEditor = ({
	form,
	block,
	mediaKitUuid,
}: {
	form: UseFormReturn<UpdateMediaKitBlock>;
	block: MediaKitPlatformBlock;
	mediaKitUuid: string;
}) => {
	const utils = trpc.useContext();
	// STATE
	const [isEditingStat, setIsEditingStat] = useState<AddStat>({
		open: false,
	});

	const getStats = () => {
		// NoAuth accounts
		if (
			((block.type === "instagram" ||
				block.type === "tiktok" ||
				block.type === "youtube") &&
				!block.isAuth) ||
			block.type === "twitch"
		) {
			return block.fields.stats;
		}
		// Auth accounts where areTabsVisible is false
		else if (
			(block.type === "facebook" || block.isAuth) &&
			!block.areTabsVisible
		) {
			return block.fields.stats;
		}
		// Auth accounts where areTabsVisible is true (and month stats is populated properly)
		else if (block.areTabsVisible && block.fields.monthStats) {
			return block.fields.monthStats;
		}
		// Error case fallback to stats
		else {
			return block.fields.stats;
		}
	};

	const stats = getStats().filter((stat) => stat.type === "value");
	const customStats = block.fields.customStats;
	const audienceStats = getStats().filter(
		(stat) => stat.type !== "value" && stat.type !== "custom",
	);

	const addCustomStat = trpc.mediaKit.addCustomStat.useMutation({
		onSuccess: () => {
			utils.mediaKit.invalidate();
		},
	});

	return (
		<div className="flex flex-col justify-between gap-8">
			<div className="flex flex-col gap-4">
				<Label variant="overline" size="xs" color="secondary">
					Account Metrics
				</Label>

				<div className="flex flex-col gap-4">
					{stats.map((stat) => (
						<PlatformStatEditor
							form={form}
							key={stat.name}
							block={block}
							stat={stat}
							setIsEditingStat={() => {
								// DO NOTHING
							}}
						/>
					))}
					{customStats &&
						customStats.map((stat) => (
							<PlatformStatEditor
								form={form}
								key={stat.name}
								block={block}
								stat={stat}
								setIsEditingStat={setIsEditingStat}
							/>
						))}
				</div>
			</div>
			<Button
				variant="secondary"
				className="min-h-10"
				size="md"
				leadingIcon={Plus}
				onClick={(e) => {
					e.preventDefault();
					setIsEditingStat({
						open: true,
						stat: undefined,
						isNew: true,
					});
				}}
			>
				Add custom stat
			</Button>
			<div className="bg-stroke-tertiary h-px w-full" />
			{audienceStats.length > 0 && (
				<>
					<div className="flex flex-col gap-4">
						<Label variant="overline" size="xs" color="secondary">
							Account Data
						</Label>

						<div className="flex flex-col gap-4">
							{audienceStats
								.sort((a, b) => {
									if (a.title === "Audience Gender") return -1;
									if (b.title === "Audience Gender") return 1;
									if (a.title === "Audience Age") return -1;
									if (b.title === "Audience Age") return 1;
									if (a.title === "Audience Country") return -1;
									if (b.title === "Audience Country") return 1;
									if (a.title === "Audience City") return -1;
									if (b.title === "Audience City") return 1;
									return a.name.localeCompare(b.name);
								})
								.map((stat) => {
									const index = block.fields.stats.findIndex(
										(s) => s.name === stat.name,
									);
									return (
										<ToggleSwitchInput
											key={stat.name}
											name={"fields.stats." + index + ".enabled"}
											label={stat.title}
											labelSize="md"
											disabled={
												(stat.type === "bar" || stat.type === "pie") &&
												stat.data &&
												Object.keys(stat.data).length !== 0 &&
												Object.values(stat.data).filter((val) => val !== 0)
													.length !== 0 &&
												block.enabled
													? false
													: true
											}
										/>
									);
								})}
						</div>
					</div>
				</>
			)}

			<UpsertCustomStatModal
				isOpen={isEditingStat.open}
				setIsOpen={() => {
					setIsEditingStat({ open: false });
				}}
				stat={isEditingStat.open === true ? isEditingStat.stat : undefined}
				onSave={(stat) => {
					if (isEditingStat.open && customStats) {
						const existingStatIndex = customStats.findIndex(
							(s) => s.name === isEditingStat.stat?.name,
						);

						const newStats = [...(customStats ?? [])];
						if (existingStatIndex === undefined || existingStatIndex === -1) {
							newStats.push(stat);
						} else {
							newStats[existingStatIndex] = stat;
						}

						addCustomStat.mutate({
							stats: newStats,
							blockUuid: block.uuid,
							mediaKitUuid: mediaKitUuid,
						});
					}
				}}
				onDelete={(stat) => {
					const existingStatIndex = customStats?.findIndex(
						(s) => s.name === stat.name,
					);

					if (existingStatIndex !== undefined && existingStatIndex !== -1) {
						const newStats = [...(customStats ?? [])];
						newStats.splice(existingStatIndex, 1);
						addCustomStat.mutate({
							stats: newStats,
							blockUuid: block.uuid,
							mediaKitUuid: mediaKitUuid,
						});
					}
				}}
			/>
		</div>
	);
};

export const PlatformStatEditor = ({
	form,
	block,
	stat,
	setIsEditingStat,
}: {
	form: UseFormReturn<UpdateMediaKitBlock>;
	block: MediaKitPlatformBlock;
	stat: MediaKitStat;
	setIsEditingStat: (statState: AddStat) => void;
}) => {
	let value;
	if (stat.type === "value") {
		if (stat.display === "percent") {
			value = stat.value.toFixed(2) + "%";
		} else {
			value = Math.round(stat.value).toLocaleString("en-US");
		}
	} else if (stat.type === "custom") {
		value = stat.value;
	}

	const insufficientData = hasInsufficientData(stat);
	const index = form
		.getValues("fields.stats")
		.findIndex((s) => s.name === stat.name);
	const customIndex = (form.getValues("fields.customStats") ?? []).findIndex(
		(s) => s.name === stat.name,
	);

	let statTitle = stat.title;
	if (stat.title == "Average Shorts Views") {
		statTitle = "Average views (Youtube Shorts)";
	} else if (stat.title === "Average Shorts Likes") {
		statTitle = "Average likes (Youtube Shorts)";
	}

	return (
		<div className="flex w-full items-center justify-between gap-2">
			<div className="flex items-center gap-4">
				{stat.type === "custom" ? (
					<div className="flex items-center">
						<ToggleSwitchInput
							name={"fields.customStats." + customIndex + ".enabled"}
							disabled={block.enabled ? false : true}
						/>
						<Label
							size="md"
							color="secondary"
							className="max-w-[130px] truncate"
						>
							{statTitle}
						</Label>
					</div>
				) : (
					<ToggleSwitchInput
						name={"fields.stats." + index + ".enabled"}
						label={statTitle}
						labelSize="md"
						disabled={
							value !== "0" && value !== "0.00%" && block.enabled ? false : true
						}
					/>
				)}
				{stat.type === "custom" ? <Tag text="Custom" color="pink" /> : null}
			</div>
			<div className="flex items-center gap-2">
				{insufficientData ? (
					<Tooltip tooltip="Not enough data" icon={Warning} />
				) : null}
				{value ? (
					<Label
						variant="paragraph"
						size="md"
						color="secondary"
						className={stat.type === "custom" ? "max-w-[100px] truncate" : ""}
					>
						{value}
					</Label>
				) : null}
				{stat.type === "custom" ? (
					<Button
						className="h-6 w-10"
						variant="secondary"
						onClick={(e) => {
							e.preventDefault();
							setIsEditingStat({ open: true, stat: stat, isNew: false });
						}}
					>
						Edit
					</Button>
				) : null}
			</div>
		</div>
	);
};

const UpsertCustomStatModal = ({
	isOpen,
	setIsOpen,
	onSave,
	stat,
	onDelete,
}: {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	stat?: MediaKitCustomPlatformValueStat;
	onSave: (stat: MediaKitCustomPlatformValueStat) => void;
	onDelete: (stat: MediaKitCustomPlatformValueStat) => void;
}) => {
	const form = useZodForm({
		schema: MediaKitCustomStatFormSchema,
		values: {
			type: "custom",
			name: stat?.name ?? "",
			title: stat?.title ?? "",
			value: stat?.value.toString() ?? "",
			enabled: stat?.enabled ?? true,
			source: "custom",
		},
		submit: (values) => {
			if (values.title !== "" && values.value.toString() !== "") {
				onSave({
					name: `custom-${values.title.toLowerCase().charAt(0)}${values.title.trim().replaceAll(" ", "").slice(1)}`,
					title: values.title,
					enabled: true,
					value: values.value,
					type: "custom",
					source: "custom",
				});
				setIsOpen(false);
			}
			form.reset();
		},
	});

	return (
		<Modal.Root isOpen={isOpen} setIsOpen={setIsOpen}>
			<Modal.Header title="Add custom stat" />
			<Modal.Body>
				<FormProvider {...form}>
					<form>
						<div className="flex flex-col gap-8">
							<ZodInput
								name="title"
								label="Stat Title"
								placeholder="Add stat title"
							/>
							<ZodInput
								name="value"
								label="value"
								placeholder="Add value"
								maxLength={15}
							/>
						</div>
					</form>
				</FormProvider>
			</Modal.Body>

			{stat ? (
				<Modal.Footer
					layout="separated"
					buttons="primary-secondary"
					primaryLabel="Save"
					onPrimaryClicked={() => {
						form.onSubmit();
					}}
					secondaryLabel="Delete"
					onSecondaryClicked={() => {
						onDelete(stat);
						setIsOpen(false);
					}}
				/>
			) : (
				<Modal.Footer
					layout="separated"
					buttons="primary"
					primaryLabel="Save"
					onPrimaryClicked={() => {
						form.onSubmit();
					}}
				/>
			)}
		</Modal.Root>
	);
};
