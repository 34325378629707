import {
	Loader,
	SubtleTabs,
	SubtleTabsContent,
	SubtleTabsList,
	SubtleTabsTrigger,
} from "@withjuly/solis";
import { useRouter } from "next/router";
import React, { ReactElement, useMemo } from "react";
import { SlideFade } from "~/components/Utility/SlideFade";
import { RouterOutput, trpc } from "~/components/Utility/trpc";
import MediaKitPreviewWebp from "~/assets/MediaKitPreview.webp";
import { cx, getThemeStyles, useWindowSize } from "@withjuly/frontend-common";
import { MediaKit as MediaKitV2 } from "~/components/MediakitsV2/view/MediaKit";
import { ScrollArea } from "@withjuly/solisv2";

interface MediaKitLayoutProps {
	mediaKitP?: RouterOutput["mediaKit"]["get"];
	children: ReactElement;
}

export const MediaKitLayout: React.FC<MediaKitLayoutProps> = ({
	mediaKitP,
	children,
}) => {
	const { data: mediaKitRes, isLoading } = trpc.mediaKit.get.useQuery();
	const router = useRouter();

	const mediaKit = useMemo(() => {
		return mediaKitP ?? mediaKitRes;
	}, [mediaKitP, mediaKitRes]);

	const window = useWindowSize();
	const isMobilePreview = useMemo(() => {
		if ((window.width ?? 1471) > 1470) {
			return false;
		} else {
			return true;
		}
	}, [window]);

	if (isLoading || !mediaKit) {
		return (
			<div className="absolute m-auto flex h-full w-full items-center justify-center">
				<Loader />
			</div>
		);
	}

	return (
		<>
			<style jsx global>
				{`
					${getThemeStyles(
						mediaKit.theme,
						mediaKit.darkMode ? "dark" : "light",
						mediaKit.customTheme,
					)}
				`}
			</style>
			<div className={cx("hidden h-full w-full flex-col md:flex md:flex-row")}>
				<div className="bg-surface-secondary flex w-full min-w-[480px] max-w-[480px] flex-col gap-4 ">
					<SlideFade
						key={mediaKit.uuid}
						offsetX={router.asPath.includes("mediakit/") ? 16 : -16}
						className="h-full"
					>
						<div className="flex h-full">{children}</div>
					</SlideFade>
				</div>
				<ScrollArea className="max-h-[calc(100vh_-_64px)]">
					<div
						className={cx(
							"bg-theme-background border-stroke-tertiary relative flex h-full w-full flex-col border-l",
						)}
					>
						<div
							className={cx(
								"mx-auto flex h-full min-h-screen max-w-[calc(100vw_-_580px)] flex-col justify-start",
								isMobilePreview && "justify-center pb-10",
							)}
						>
							{!isMobilePreview ? (
								<MediaKitV2
									key={mediaKit.blocks.length}
									mediaKit={mediaKit}
									isPreview
									isMobilePreview={isMobilePreview}
									hideNav={true}
								/>
							) : (
								<div className="flex h-full w-full flex-col items-center justify-center">
									<div
										className="h-full max-h-[54rem] w-full max-w-[28rem] rounded-2xl px-8 py-4"
										style={{
											backgroundImage: `url('${MediaKitPreviewWebp.src}')`,
											backgroundSize: "contain",
											backgroundRepeat: "no-repeat",
											backgroundPosition: "center",
										}}
									>
										<ScrollArea className="rounded-[48px]">
											<div className="h-full max-h-[52rem] w-full max-w-[24rem] rounded-[48px]">
												<MediaKitV2
													key={mediaKit.blocks.length}
													mediaKit={mediaKit}
													isPreview
													isMobilePreview={isMobilePreview}
													hideNav={true}
												/>
											</div>
										</ScrollArea>
									</div>
								</div>
							)}
						</div>
					</div>
				</ScrollArea>
			</div>

			<div className="flex h-full min-w-full px-4 md:hidden">
				<SubtleTabs defaultValue="edit" className="w-full pb-24">
					<SubtleTabsContent value="edit">
						<SlideFade
							className="w-full"
							key={router.asPath}
							offsetX={router.asPath.includes("mediakit/") ? 16 : -16}
						>
							<div className="flex w-full">{children}</div>
						</SlideFade>
					</SubtleTabsContent>
					<SubtleTabsContent value="preview" className="flex w-full">
						<MediaKitV2 mediaKit={mediaKit} isPreview hideNav={true} />
					</SubtleTabsContent>
					<SubtleTabsContent value="preview"></SubtleTabsContent>
					<SubtleTabsList className="fixed bottom-0 left-0 z-20 w-full items-center border-t border-gray-500 bg-gray-800  px-4 py-6">
						<SubtleTabsTrigger value="edit">Editor</SubtleTabsTrigger>
						<SubtleTabsTrigger value="preview">Preview</SubtleTabsTrigger>
					</SubtleTabsList>
				</SubtleTabs>
			</div>
		</>
	);
};
