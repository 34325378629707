import {
	MediaKit,
	MediaKitFacebookBlock,
	MediaKitInstagramBlock,
	MediaKitPlatformBlock,
	MediaKitTikTokBlock,
	MediaKitYouTubeBlock,
	UpdateMediaKitBlock,
	UpdateMediaKitBlockSchema,
} from "@withjuly/fabric";
import {
	ArrowLeft,
	CaretLeft,
	CaretRight,
	ChartBar,
} from "@withjuly/julycons/bold";
import {
	Button,
	IconButton,
	Label,
	Modal,
	ScrollArea,
	ToggleSwitch,
} from "@withjuly/solisv2";
import { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { TikTokDataEntry } from "~/components/AccountConnection/TikTok/TikTokDataEntry";
import { ToggleSwitchInput } from "~/components/Input/ToggleSwitchInput";
import { PlatformData } from "~/components/Mediakits/store";
import { ActiveEditorType } from "~/pages/creator/mediakit";
import { useUpdateMediaKitBlock } from "~/utils/api/query/mediakit";
import { useZodForm } from "~/utils/hooks/zod-form";
import { getBlocksOfType } from "~/utils/mediakits";
import { PlatformStatsEditor } from "./PlatformStatsEditor";

export const PlatformBlockEditor = ({
	platform,
	onClickBack,
	mediaKit,
}: {
	platform: string;
	onClickBack: (editor: ActiveEditorType) => void;
	mediaKit: MediaKit;
}) => {
	const updateBlock = useUpdateMediaKitBlock();
	const [isTikTokDataModalOpen, setIsTikTokDataModalOpen] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);

	const blocks = getBlocksOfType<MediaKitPlatformBlock>(
		mediaKit.blocks,
		platform,
	);

	const block = useMemo(() => {
		return blocks.blocks[activeIndex];
	}, [activeIndex, blocks]);

	const formatBlockForEditing = (
		block:
			| MediaKitFacebookBlock
			| MediaKitInstagramBlock
			| MediaKitTikTokBlock
			| MediaKitYouTubeBlock,
	): UpdateMediaKitBlock => {
		if (!block || !block.fields) return block;

		const formattedStats = Array.from(
			new Map(
				[
					...(block.fields.stats ?? []),
					...(block.fields.monthStats ?? []),
					...(block.fields.weekStats ?? []),
					...(block.fields.twoWeekStats ?? []),
				].map((stat) => [`${stat.name}|${stat.type}|${stat.enabled}`, stat]),
			).values(),
		);

		return {
			...block,
			fields: {
				...block.fields,
				recentPosts: block.fields.recentPosts,
				stats: formattedStats,
			},
		};
	};

	const form = useZodForm({
		schema: UpdateMediaKitBlockSchema,
		values:
			block &&
			(block.type === "facebook" ||
				block.type === "instagram" ||
				block.type === "tiktok" ||
				block.type === "youtube")
				? formatBlockForEditing(block)
				: block,
		submit: (data) => {
			updateBlock.mutate({
				uuid: block?.uuid ?? "",
				data: {
					...data,
					order: undefined,
				},
			});
		},
	});

	const { onSubmit, watch } = form;
	useEffect(() => {
		const { unsubscribe } = watch(() => {
			onSubmit();
		});

		return () => unsubscribe();
	}, [watch, onSubmit]);

	if (!block) {
		return null;
	}

	const platformData = PlatformData[block.type];

	return (
		<div className="flex w-full flex-col rounded-lg">
			<div className="flex w-full items-center justify-between px-8 py-[22px]">
				<div className="flex items-center gap-4">
					<IconButton
						icon={ArrowLeft}
						size="sm"
						variant="secondary"
						onClick={() => onClickBack("none")}
					/>
					<div>
						<div className="flex items-center gap-2">
							<platformData.icon className="h-4 w-4" />
							<p className="text-header-lg font-repro">{platformData.name}</p>
						</div>

						<p className="text-paragraph-sm font-repro text-text-secondary">
							{block.fields.username}
						</p>
					</div>
				</div>
				{blocks.blocks.length > 1 ? (
					<div className="flex flex-row gap-2">
						<IconButton
							variant="secondary"
							size="xs"
							icon={CaretLeft}
							disabled={activeIndex === 0}
							onClick={() => setActiveIndex((idx) => idx - 1)}
						/>
						<IconButton
							variant="secondary"
							size="xs"
							icon={CaretRight}
							disabled={activeIndex === blocks.blocks.length - 1}
							onClick={() => setActiveIndex((idx) => idx + 1)}
						/>
					</div>
				) : null}
			</div>
			<div className="bg-stroke-secondary h-px w-full" />
			<ScrollArea className="max-h-[calc(100vh_-_152px)] overflow-y-scroll">
				{blocks.blocks.length > 1 ? (
					<div className="flex flex-col">
						<div className="flex flex-row items-center gap-2 px-8 py-[22px]">
							<ToggleSwitch
								toggle={block.enabled}
								onToggle={() => {
									if (block) {
										updateBlock.mutate({
											uuid: block.uuid,
											data: {
												...block,
												type: block.type,
												enabled: !block.enabled,
											},
										});
									}
								}}
							/>
							<Label size="md" color="secondary">
								Show this account on Media Kit
							</Label>
						</div>
						<div className="bg-stroke-secondary h-px w-full" />
					</div>
				) : null}
				<FormProvider {...form}>
					<form className="flex flex-col gap-8 p-8">
						<div className="flex flex-col gap-4">
							<Label variant="overline" size="xs" color="secondary">
								Content
							</Label>
							<ToggleSwitchInput
								name="fields.recentPosts.enabled"
								label="Show recent posts"
								labelSize="md"
								disabled={block.enabled ? false : true}
							/>
						</div>

						<div className="bg-stroke-tertiary h-px w-full" />
						<div className="flex flex-col">
							<PlatformStatsEditor
								form={form}
								block={block}
								mediaKitUuid={mediaKit.uuid}
							/>
						</div>
					</form>
				</FormProvider>

				{block.type === "tiktok" ? (
					<div className="flex w-full flex-col px-8 pb-8">
						<Button
							size="md"
							variant="primary"
							onClick={() => setIsTikTokDataModalOpen(() => true)}
							leadingIcon={ChartBar}
							className="min-h-10"
						>
							Add TikTok Data
						</Button>
					</div>
				) : null}
			</ScrollArea>
			<Modal.Root
				isOpen={isTikTokDataModalOpen}
				setIsOpen={setIsTikTokDataModalOpen}
			>
				<TikTokDataEntry setIsOpen={setIsTikTokDataModalOpen} />
			</Modal.Root>
		</div>
	);
};
