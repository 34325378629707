import { FormInputWrapper } from "@withjuly/solis";
import { useId } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Label, ToggleSwitch } from "@withjuly/solisv2";

interface ToggleSwitchInputProps {
	name: string;
	disabled?: boolean;
	label?: string;
	size?: "sm" | "md";
	tooltip?: string;
	labelSize?: "2xs" | "xs" | "sm" | "md" | "lg";
	labelVariant?: "bold" | "overline" | "paragraph";
}

export const ToggleSwitchInput: React.FC<ToggleSwitchInputProps> = ({
	name,
	label,
	disabled,
	tooltip,
	size = "sm",
	labelSize = "sm",
	labelVariant = "paragraph",
}) => {
	const { control } = useFormContext();
	const id = useId();

	return (
		<FormInputWrapper name={name} id={id}>
			<div className="flex flex-row items-center gap-2">
				<Controller
					key={name}
					control={control}
					name={name}
					render={({ field }) => (
						<ToggleSwitch
							id={name}
							toggle={field.value}
							onToggle={field.onChange}
							disabled={disabled}
							size={size}
							{...field}
						/>
					)}
				/>
				<Label
					size={labelSize}
					tooltip={tooltip}
					variant={labelVariant}
					color="secondary"
				>
					{label}
				</Label>
			</div>
		</FormInputWrapper>
	);
};
